import { Injectable, InjectionToken, PLATFORM_ID, ApplicationRef, inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, ReplaySubject, timer } from 'rxjs';
import { shareReplay, distinctUntilChanged, switchMap, first, catchError } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';

export interface StockMarketServiceConfigurationInterface {
  baseUrl: URL;
  onServer?: {
    baseUrl?: URL;
  };
  refreshInterval: number;
}

export const stockMarketServiceConfiguration = new InjectionToken<StockMarketServiceConfigurationInterface>('stockMarketServiceConfiguration');

export interface PriceResponse {
  currency: string;
  values: {
    price: number;
    datetime: number;
  }[];
  startPrice: number;
}

export enum ErrorType {
  Connection,
  Unknown,
}

@Injectable({
  providedIn: 'root',
})
export class StockMarketService {

  private baseUrl: string;
  private refreshInterval: number;

  public readonly changes$: Observable<PriceResponse>;

  private readonly errorTypeSubject$ = new ReplaySubject<ErrorType>(1);
  public readonly errorType$ = this.errorTypeSubject$.asObservable();

  private http = inject(HttpClient);

  public constructor() {
    const configuration = inject(stockMarketServiceConfiguration);
    const isServer = isPlatformServer(inject(PLATFORM_ID));
    const baseUrl = (configuration.onServer?.baseUrl && isServer ? configuration.onServer.baseUrl : configuration.baseUrl)
    this.baseUrl = baseUrl.toString();
    this.refreshInterval = configuration.refreshInterval;

    if (isServer) {
      this.changes$ = this.fetchPrice().pipe(
        shareReplay(1),
      );
    } else {
      this.changes$ = inject(ApplicationRef).isStable.pipe(
        first(stable => stable),
        switchMap(() => timer(0, this.refreshInterval)),
        switchMap(() => this.fetchPrice()),
        distinctUntilChanged((previous, current) =>
          previous.values.length === current.values.length,
        ),
        shareReplay({bufferSize: 1, refCount: true}),
      );
    }
  }

  private fetchPrice(): Observable<PriceResponse> {
    return this.http.get<PriceResponse>(`${this.baseUrl}/price`).pipe(
      catchError((error) => {
        let errorType: ErrorType = ErrorType.Unknown;
        if (error instanceof HttpErrorResponse) {
          if (error.status === 0) {
            errorType = ErrorType.Connection;
          }
        }
        this.errorTypeSubject$.next(errorType);
        return EMPTY; // Do not stop timer on error // error is logged with http-logger.interceptor
      }),
    );
  }

}
